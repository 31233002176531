<template>
  <div class="card card-custom card-transparent">
    <div v-if="submitStatus === 'ERROR'">
      <b-alert show variant="danger" 
        ><i class="fas fa-exclamation-circle text-white mr-3"></i>

        {{$t('Alert')}}</b-alert
      >
    </div>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
        :dir="$t('clan')=='ar'?'rtl':'ltr'"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title mr-5">{{$t('NewTarrif')}}</div>
                  <div class="wizard-desc">{{$t('AddNewTarrif')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                    
                  >
                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.tarrif.tarrif_name.$error }"
                    >
                      <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('TarrifName')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        :placeholder="$t('TarrifName')"
                        
                        :style="$t('clan')=='ar'?'text-align:right':''"
                        v-model="tarrif.tarrif_name"
                        :class="{ 'form-input--error': $v.tarrif.tarrif_name.$error }"
                        :state="$v.tarrif.tarrif_name.$error === true ? false : null"
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                    </div>
                <div class="text-right">
                  <button class="btn btn-primary " @click.prevent="addZone">+ ADD</button>
                </div>
                
                <div >

                    
              
                    <b-input-group class="mb-2 mr-sm-2 mb-sm-0  mt-4" v-for="zone in tarrif.zones" :key="zone.id"> 
                  <b-input-group-prepend>
                   <b-input-group-text style="width: 120px"> {{zone.name}}</b-input-group-text>
                  </b-input-group-prepend>

                  <b-form-input
                  v-model="zone.distance"
                     
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text> Price </b-input-group-text>
                  </b-input-group-append>
                  <b-form-input
                    v-model="zone.price"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text> {{$t('JOD')}} </b-input-group-text>
                    <b-input-group-text class="bg-danger text-light" @click="deleteZone(zone.id)"> Delete </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>

                  </div>
                    

                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div class="mr-2"></div>
                      <div>
                        <button
                          @click.prevent="submit"
                          class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                          data-wizard-type="action-submit"
                        >
                          {{$t('Submit')}}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
    </div>
  </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateTarrif",

  data() {
    return {
      tarrif: {
        tarrif_name: null,
        zones:[
          {
          id:1,price:'',distance:'',name:'zone1'
        },
        
        ]
        
      },
      submitStatus: null,
    };
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "ClientsTarrif", route: "/admin/clients-tarrif" },
      { title: "NewTarrif" },
    ]);

    //this.createData = await this.$store.dispatch("queues/fetchCreateData");

    /*await this.$store.dispatch("clients/getZones").then((res)=>{
      this.zones = res
      this.loading = false
    })*/

    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1,
      clickableSteps: true,
    });

    wizard.on("beforeNext", function (/*wizardObj*/) {});

    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    deleteZone(id){
      //console.log(id)
      this.tarrif.zones = this.tarrif.zones.filter(s=>s.id !== id)
      for(var s=0 ; s<this.tarrif.zones.length ; s++){
        this.tarrif.zones[s].id = s+1
        //console.log(this.tarrif.zones[s])
      }

    },
    addZone(){
      this.tarrif.zones.push({
          id:this.tarrif.zones.length+1 ,price:'',distance:'',name:'zone'+(this.tarrif.zones.length + 1)
        },)
  console.log(this.tarrif.zones)
    },
      /*setToggle(){
          if(this.toggled === true){
        this.toggled = false;
        this.toggleValue='Fixed'
        
        

      }
      else if(this.toggled === false){
        this.toggled = true
        this.toggleValue='Percentage'
       
        
      }
      },*/
     submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
         this.$store.dispatch("clientTarrif/create", this.tarrif);

        Swal.fire({
          title: "",
          text: "The tarrif has been created successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.$router.push({ name: "clientstarrif" });
        this.$store.dispatch("clientTarrif/loadAll");

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
  },
  validations: {
    tarrif: {
      tarrif_name: {
        required,
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
.te:hover{
  color: rgb(247, 71, 71);
  cursor: pointer;
} 
</style>